.floating-suggestions{
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  z-index: 10001;
  overflow: auto;
  width: 100%;
  justify-content: center;
}

.create-bot-button{
  background-color: #1e90ff;
  padding: 0.2rem;
  border-radius: 0.5rem;
  color: white;
  // top: 0;
  right: 0;
  position: absolute;
  margin:6rem -6rem 0 0;
  cursor: pointer;
  rotate: 90deg;
  z-index: 10;
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat-box-wrapper {
  width: 90%;
  background-color: #f6f6f6;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .chat-box {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .chat-boxes-left{
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .chat-boxes-right{
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
  }
}

@media screen and (max-width: 500px) {
  .chat-boxes-left{
      margin-left: 0 !important;
  }

  .chat-box{
      width: 88% !important;
  }
}
